<template>
    <div class="login-portal-form-wrap login-iframe">
        <div class="login-before">
            <x-addon-code
                page="controllers\\portal\\LoginController"
                location="before_content"
                :app="this"
            />
        </div>

        <h4
            v-show="showAlreadyLogged"
            id="open_portal_link"
            class="text-center"
        >
            {{ $t('portal', 'You are already logged - ') }}
            <a
                :href="loggedUrl"
                target="_blank"
            >{{ $t('portal', 'open portal') }}</a>
        </h4>

        <div
            v-show="showFormTitle"
            class="login-portal-heading"
        >
            {{ $t('portal', 'Customer login') }}
        </div>

        <form
            v-show="!isLogged && showForm"
            id="customerLoginForm"
            ref="customerLoginForm"
            method="POST"
            class="form-signin"
        >
            <x-input-hidden
                id="security_code_1"
                v-model="model.security_code_1"
                name="LoginForm[security_code_1]"
            />
            <x-input-hidden
                id="security_code_2"
                v-model="model.security_code_2"
                name="LoginForm[security_code_2]"
            />
            <div class="form-input-group login">
                <x-complex-input
                    v-model="model.login"
                    type="text"
                    :params="{
                        options: {
                            'placeholder': authBy === 'email' ? $t('portal', 'Email address') : $t('portal', 'Login'),
                            'name': 'LoginForm[login]',
                            'class': 'form-control',
                            'autocomplete': 'username',
                            'autofocus': false,
                            'required': true,
                        },
                    }"
                />
            </div>
            <div class="form-input-group">
                <input
                    id="password"
                    v-model="model.password"
                    type="password"
                    name="LoginForm[password]"
                    class="form-control password"
                    :placeholder="$t('portal', 'Password')"
                    autocomplete="old-password"
                    required
                >
            </div>

            <div class="form-input-group">
                <button
                    id="submit_form"
                    class="btn btn-sm btn-primary btn-block"
                    @click.prevent="handleForm"
                >
                    {{ $t('portal', 'Sign in') }}
                </button>
            </div>
        </form>

        <div class="login-after">
            <x-addon-code
                page="controllers\\portal\\LoginController"
                location="after_content"
                :app="this"
            />
        </div>
    </div>
</template>

<script>
import XAddonCode from '@/components/base/XAddonCode/XAddonCode';
import XComplexInput from '@/components/common/inputs/XComplexInput/XComplexInput';
import { XInputHidden } from '@/components/common/inputs/XInputHidden';

/* global md5, base64_encode */
export default {
    name: 'LoginIframe',
    components: {
        XComplexInput, XAddonCode, XInputHidden,
    },
    data() {
        return {
            showForm: !window.already_logged_url,
            showFormTitle: !window.already_logged_url,
            showAlreadyLogged: !!window.already_logged_url,
            model: {
                security_code_1: '',
                security_code_2: '',
                login: '',
                password: '',
            },
        };
    },
    methods: {
        handleForm() {
            const { customerLoginForm } = this.$refs;
            if (!customerLoginForm.checkValidity()) {
                customerLoginForm.reportValidity();
                return false;
            }
            $(customerLoginForm).submit();
        },
        checkCookie() {
            if (!this.isLogged) {
                // Fix problem with third-party cookies
                if ($.cookie(window.sessionName) === undefined) {
                    const { customerLoginForm } = this.$refs;
                    let passwordInput = $('#password');
                    let securityCode2Input = $('#security_code_2');

                    $(customerLoginForm).off();
                    $(customerLoginForm).attr('action', '/portal/login--without-cookie');
                    $(customerLoginForm).attr('target', '_blank');
                    $(customerLoginForm).append(`<input type="hidden" name="old_security_code_2" value="${this.model.security_code_2}">`);

                    $(customerLoginForm).submit(() => {
                        let securityCode2 = $(securityCode2Input).val();
                        let password = $(passwordInput).val();
                        $(securityCode2Input).val(this.passwordHash(password, securityCode2));
                        $(passwordInput).val('');
                        this.showForm = false;
                        this.showFormTitle = false;
                        this.showAlreadyLogged = true;
                    });
                }
            }
        },
        passwordHash(p1, p2) {
            return md5(base64_encode(md5(p1) + md5(p2)));
        },
    },
    mounted() {
        if (window.isValidJSON(window.codes)) {
            window.codes = JSON.parse(window.codes);
            this.model.security_code_1 = window.codes.security_code_1;
            this.model.security_code_2 = window.codes.security_code_2;
        }
        $.getScript('/js/development/php.min.js', () => {
            this.checkCookie();
        });
    },
    computed: {
        isLogged() {
            return !!window.already_logged_url;
        },
        loggedUrl() {
            return '/portal';
        },
        authBy() {
            return window.auth_by;
        },
    },
};
</script>

<style>
    @import '../../../../design/pages/portal/login/login-iframe.scss';
</style>
