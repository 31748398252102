<template>
    <ul
        class="language"
        style="opacity: 0"
    >
        <li class="dropdown">
            <a
                href="javascript:void(0)"
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                role="button"
                aria-expanded="false"
                @click="toggleDropdown"
            >
                {{ $t('portal', 'Select language..') }} <span class="caret" /></a>
            <ul
                v-show="showDropdown"
                class="dropdown-menu"
                role="menu"
            >
                <li
                    v-for="(language, code) in languages"
                    :key="code"
                >
                    <a @click="change_portal_language(code)">{{ language }}</a>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
import { supports_html5_storage } from '@/utils/functions';

export default {
    name: 'XLanguageSelector',
    props: {
        languages: {
            type: [Object, Array],
        },
    },
    data() {
        return {
            showDropdown: false,
        };
    },
    mounted() {
        this.check_portal_language();
    },
    methods: {
        change_portal_language(code) {
            if (supports_html5_storage()) {
                localStorage.setItem('portal_language', code);
            }
            let self = this;
            $.get(`/portal/login--set-language?language=${code}`, (data) => {
                if (data.result) {
                    self.$router.go(self.$router.currentRoute);
                }
            });
        },
        get_portal_language() {
            if (supports_html5_storage()) {
                return localStorage.getItem('portal_language');
            }
            return false;
        },
        check_portal_language() {
            let saved_language = this.get_portal_language();
            let portal_language = window.spl_config.language;
            if (saved_language !== false && portal_language.length != 0 && saved_language !== portal_language) {
                this.change_portal_language(saved_language);
            }
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
    },
};
</script>
