import PortalLogin from '@/apps/portal-login/views/login/Login.vue';
import LoginIframe from '@/apps/portal-login/views/login/LoginIframe';

const ResetPassword = () => import(/* webpackChunkName: "reset_password" */ '@/apps/portal-login/views/reset-password/ResetPassword');
const VerifyCode = () => import(/* webpackChunkName: "reset_password" */ '@/apps/portal-login/views/reset-password/VerifyCode');
const SetNewPassword = () => import(/* webpackChunkName: "reset_password" */ '@/apps/portal-login/views/reset-password/SetNewPassword');

export default [
    {
        name: 'portal.login.iframe',
        path: 'login-iframe',
        component: LoginIframe,
    },
    {
        name: 'portal.login.without_cookie',
        path: 'login--without-cookie',
        component: PortalLogin,
    },
    {
        name: 'portal.login',
        path: 'login',
        component: PortalLogin,
    },
    {
        path: 'reset-password',
        name: 'portal.reset_password',
        component: ResetPassword,
    },
    {
        path: 'reset-password--verify-check-code',
        name: 'portal.reset_password.verify',
        component: VerifyCode,
    },
    {
        path: 'reset-password--set-new-password',
        name: 'portal.reset_password.set_new_password',
        component: SetNewPassword,
    },
];
