<template>
    <router-view />
</template>

<script>
export default {
    name: 'App',
};
</script>

<style lang="scss">
@import '../../design/pages/portal/login/login';
@import '../../design/pages/portal/login/portal-login-page.scss';
</style>
