import Vue from 'vue';

import $ from 'jquery';

window.Vue = Vue;
window.$ = $;
window.jQuery = $;
global.$ = $;
global.jQuery = $;

require('@/../web/js/development/jquery.address.min');
require('@/../web/js/development/jquery.cookie');
require('@/../web/js/development/jquery.noty.packaged.min');
require('@/../web/js/development/jquery.noty.manager');
require('@/../web/js/development/functions');

require('@/../web/js/development/csrf');
