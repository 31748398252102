import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import Locale from '@/locale';
import XNotificationsPlugin from '@/plugins/XNotificationsPlugin';
import router from './router';
import App from './App';
import store from '../portal/store';

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(XNotificationsPlugin);

if (typeof window.spl_config === 'undefined') {
    window.spl_config = {
        language: 'en',
        header: {
            logo: '',
        },
    };
}

let locale = new Locale(window.spl_config.language);

require('./bootstrap');

window.xApp = new Vue({
    router,
    store: new Vuex.Store(store),
    splang: locale.splang,
    render: (h) => h(App),
});
xApp.$mount('#app');

Vue.config.devtools = process.env.NODE_ENV === 'development';
