import VueRouter from 'vue-router';
import { flatRoutes } from '@/utils/router/flat-routes';
import { mergeBreadcrumbs } from '@/utils/router/merge-breadcrumbs';
import PortalRoutes from './routes/portal';

const routes = flatRoutes(mergeBreadcrumbs([
    {
        path: '/',
        redirect: (to) => {
            to({ name: 'portal.login' });
        },
    },
    {
        path: '/portal',
        children: PortalRoutes,
    },
]));

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
