// Environment
export const PRODUCTION = 'production';

export const ACS_PROVISIONING_NEW_KEY = '----new----';

export const GLOBAL_NOTIFICATION_TYPE_MESSAGE = 'message';
export const GLOBAL_NOTIFICATION_TYPE_MESSAGE_AND_ACTION = 'message_and_action';

export const LAYOUT_EMPTY = 'empty_layout';

// CSS in JS
export const SPACER = 4;

export const ADMIN_PART = 'admin';
export const PORTAL_PART = 'portal';
export const SELECT_VALUE_ALL = '_all_';
export const SELECT_ALL = 'all';

export const SERVICE_FORM_TYPES = {
    internet: 'ServicesInternet',
    voice: 'ServicesVoice',
    bundle: 'ServicesBundle',
    recurring: 'ServicesCustom',
};

export const COMMON_BADGE_COLORS = {
    success: 'bg-success',
    pending: 'bg-pending',
    primary: 'bg-primary',
    dark: 'bg-dark',
    purple: 'bg-purple',
    danger: 'bg-danger',
    warning: 'bg-warning',
};

export const GDPR_ANSWER_ACCEPT = 'accept';
export const GDPR_ANSWER_DECLINE = 'decline';
export const GDPR_ANSWER_EMPTY = 'empty_answer';

export const PHOTO_MAX_SIZE = 5242880; // ~ 5 MB

export const SERVICE_TYPE_COLORS = {
    internet: 'color-success',
    voice: 'color-info',
    bundle: 'color-pink',
    recurring: 'color-purple',
    one_time: 'color-warning',
};

export const FINANCE_DOCUMENT_BADGE_COLORS = {
    payment: 'bg-success',
    credit_note: 'bg-pending',
    transaction: 'bg-primary',
    invoice: 'bg-dark',
    recurring_invoice: 'bg-dark',
    proforma_invoice: 'bg-primary',
    one_time_invoice: 'bg-purple',
    future_item: 'bg-warning',
    default: 'bg-primary',
};

export const TARIFF_VOICE = 'voice';
export const TARIFF_RECURRING = 'recurring';
export const TARIFF_INTERNET = 'internet';
export const TARIFF_ONE_TIME = 'one-time';
export const TARIFF_BUNDLE = 'bundles';
