<template>
    <div class="splynx-wrapper portal-login-page">
        <x-global-notifications />
        <slot />
    </div>
</template>

<script>
import XGlobalNotifications from '@/components/base/XGlobalNotification/XGlobalNotification';

export default {
    name: 'PortalLoginLayout',
    components: { XGlobalNotifications },
};
</script>
<style>
    .pswp {
        display: none;
    }
</style>
