<template>
    <portal-login-layout>
        <x-language-selector :languages="languages" />
        <div class="main">
            <div
                class="side-wrap"
                style="opacity: 0"
            >
                <div class="login-after">
                    <x-addon-code
                        ref="addon_code_after"
                        page="controllers\portal\LoginController"
                        location="after_content"
                        :app="this"
                    />
                </div>
                <div class="splynx-wellcome">
                    <h3>{{ $t('portal', 'Customer portal') }}</h3>
                </div>
            </div>
            <div
                class="login-portal-form-wrap"
                style="opacity: 0"
            >
                <div
                    class="splynx-logo"
                    v-html="logo"
                />
                <div class="login-portal-form">
                    <div class="login-before">
                        <x-addon-code
                            ref="addon_code_before"
                            page="controllers\portal\LoginController"
                            location="before_content"
                            :app="this"
                        />
                    </div>

                    <div class="login-portal-heading">
                        {{ $t('portal', 'Customer login') }}
                    </div>

                    <div
                        v-if="twoFactorForm.processing"
                        class="login-portal-info"
                    >
                        {{ twoFactorInfo }}
                        <div class="text-danger">
                            {{ $t('portal', 'Please allow for up to two minutes for the code to come through') }}
                        </div>
                    </div>

                    <div class="form-holder">
                        <x-form
                            id="loginForm"
                            ref="form"
                            :check-unsaved-data-before-leave-page="false"
                            method="POST"
                            class="form-signin"
                            role="form"
                            autocomplete="on"
                        >
                            <x-input-hidden
                                id="security_code_1"
                                ref="securityCode1"
                                v-model="security_code_1"
                                name="LoginForm[security_code_1]"
                            />
                            <x-input-hidden
                                id="security_code_2"
                                ref="securityCode2"
                                v-model="security_code_2"
                                name="LoginForm[security_code_2]"
                            />

                            <div
                                v-show="!twoFactorForm.processing"
                                class="form-input-group"
                            >
                                <input
                                    id="login"
                                    ref="loginInput"
                                    v-model="login"
                                    type="text"
                                    name="LoginForm[login]"
                                    class="form-control"
                                    :placeholder="auth_by == 'email' ? $t('portal', 'Email address') : $t('portal', 'Login')"
                                    autocomplete="username"
                                    :disabled="loading"
                                    autofocus
                                    required
                                >
                            </div>

                            <div
                                v-show="!twoFactorForm.processing"
                                class="form-input-group"
                            >
                                <input
                                    id="password"
                                    ref="passwordInput"
                                    v-model="password"
                                    type="password"
                                    name="LoginForm[password]"
                                    class="form-control password"
                                    placeholder="Password"
                                    autocomplete="old-password"
                                    :disabled="loading"
                                    required
                                >
                            </div>

                            <div
                                v-show="twoFactorForm.processing"
                                class="form-input-group"
                            >
                                <x-complex-input
                                    ref="codeInput"
                                    v-model="twoFactorForm.code"
                                    type="text"
                                    :params="{
                                        name: 'LoginForm[code]',
                                        options: {
                                            id: 'code-input',
                                            class: 'form-control',
                                            placeholder: $t('portal', 'Code'),
                                            autocomplete: 'code',
                                            autofocus: true,
                                        }
                                    }"
                                    :meta="{ rule: { required: twoFactorForm.processing } }"
                                    :disabled="loading"
                                />
                            </div>

                            <div
                                v-if="errorName"
                                id="error"
                            >
                                <div
                                    id="static"
                                    class="login-error"
                                >
                                    {{ errorMessage }}
                                </div>
                            </div>

                            <div class="form-input-group mb-12">
                                <input
                                    id="submit"
                                    type="button"
                                    class="btn btn-sm btn-primary btn-block"
                                    :value="$t('portal', 'Sign in')"
                                    @click.prevent="submitForm"
                                >
                                <x-bootstrap-spinner
                                    v-show="loading"
                                    type="primary"
                                    size="md"
                                />
                            </div>

                            <div
                                v-show="twoFactorForm.processing"
                                class="form-input-group mb-12"
                            >
                                <input
                                    id="resend-code"
                                    type="button"
                                    :class="resendCodeClasses"
                                    :value="$t('portal', 'Resend code')"
                                    @click.prevent="resendCode"
                                >
                            </div>

                            <Transition name="slide">
                                <div
                                    v-show="showResendCodeTimer"
                                    id="seconds-left"
                                    class="form-input-group mb-12"
                                >
                                    <span>
                                        {{ $t('portal', '{seconds} seconds to resend again', { seconds: twoFactorForm.secondsLeft }) }}
                                    </span>
                                </div>
                            </Transition>

                            <div
                                v-show="!twoFactorForm.processing"
                                class="apps-row mt-12"
                            >
                                <router-link
                                    v-if="reset_password"
                                    to="/portal/reset-password"
                                    class="reset-password-link btn btn-link px-12 btn-icon-text"
                                >
                                    <div class="icon-wrap">
                                        <i
                                            class="icon-ic_fluent_key_24_regular"
                                        />
                                    </div>
                                    {{ $t('portal', 'Reset password') }}
                                </router-link>
                                <a
                                    v-if="need_help_url"
                                    :href="need_help_url"
                                    target="_blank"
                                    class="help-link btn btn-link px-12 btn-icon-text ms-auto"
                                >
                                    <div class="icon-wrap">
                                        <i
                                            class="icon-ic_fluent_chat_help_24_regular"
                                        />
                                    </div>
                                    {{ $t('portal', 'Need help?') }}
                                </a>
                            </div>
                        </x-form>
                        <div
                            v-if="mobile_app_config.enabled"
                            class="apps-row mt-28"
                        >
                            <a
                                v-if="mobile_app_config.app_store_application_id"
                                id="app-store"
                                :href="'https://apps.apple.com/app/'+mobile_app_config.app_store_application_id"
                                target="_blank"
                            >
                                <img
                                    src="/images/app/app-store.svg"
                                    :alt="$t('portal', 'Download on the App Store')"
                                >
                            </a>
                            <a
                                v-if="mobile_app_config.play_market_application_id"
                                id="play-market"
                                :href="'https://play.google.com/store/apps/details?id='+mobile_app_config.play_market_application_id+'&hl=en'"
                                target="_blank"
                            >
                                <img
                                    src="/images/app/google-play.svg"
                                    :alt="$t('portal', 'Get it on Google Play')"
                                >
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </portal-login-layout>
</template>

<script>
import PortalLoginLayout from '@/components/layouts/PortalLoginLayout';
import { XAddonCode } from '@/components/base/XAddonCode';
import { XForm } from '@/components/common/XForm';
import { XInputHidden } from '@/components/common/inputs/XInputHidden';
import { XBootstrapSpinner } from '@/components/common/XBootstrapSpinner';
import { XLanguageSelector } from '@/components/common/XLanguageSelector';
import { XComplexInput } from '@/components/common/inputs/XComplexInput';
import { promisifiedAjax, formattedResponse } from '@/utils/functions';

/* global md5, base64_encode, csrf */
export default {
    name: 'PortalLogin',
    data() {
        return {
            errorName: '',
            useOldSecurityCodeSecond: true,
            afterError: false,
            security_code_1: '',
            security_code_2: '',
            reset_password: true,
            need_help_url: '',
            loading: false,
            auth_by: '',
            mobile_app_config: {},
            languages: [],
            twoFactorForm: {
                active: false,
                processing: false,
                code: '',
                recipient: [],
                generatedCode: false,
                twoFactorAuthType: '',
                permissionResendCode: false,
                interval: false,
                updateTimeout: false,
                secondsLeft: 0,
                timeoutForResetCode: 0,
            },
            login: '',
            password: '',
        };
    },
    beforeCreate() {
        $.getScript('/js/development/php.min.js', () => {
            let isWithoutCookie = window.isValidJSON(window.without_cookie);
            if (isWithoutCookie) {
                window.without_cookie = JSON.parse(window.without_cookie);
                this.withoutCookie(window.without_cookie);
            }
        });
    },
    beforeMount() {
        if (window.already_logged_url) {
            this.$router.push(window.already_logged_url);
            return false;
        }
        if (window.login_iframe) {
            this.$router.push({ name: 'portal.login.iframe' });
            return false;
        }
        if (window.without_cookie) {
            return false;
        }
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/portal/login--get-security-codes',
                success: (data) => {
                    this.security_code_1 = data.security_code_1;
                    this.security_code_2 = data.security_code_2;
                    this.reset_password = data.reset_password === true;
                    this.need_help_url = data.need_help_url;
                    this.auth_by = data.auth_by;
                    this.mobile_app_config = data.mobile_app_config;
                    this.languages = data.languages;
                    this.twoFactorForm.active = data.two_factor_authentication_status;
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
    methods: {
        submitForm() {
            if (this.$refs.form.$refs.form.reportValidity()) {
                this.handleLogin();
            }
        },
        $_GET(param) {
            let vars = {};
            window.location.href.replace(window.location.hash, '').replace(
                /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
                (m, key, value) => { // callback
                    vars[key] = value !== undefined ? value : '';
                },
            );

            if (param) {
                return vars[param] ? vars[param] : null;
            }
            return vars;
        },
        password_hash(p1, p2) {
            return md5(base64_encode(md5(p1) + md5(p2)));
        },
        withoutCookie(controllerParams) {
            const { form } = this.$refs.form.$refs;
            let url = '/portal/login';
            let postData = {};

            $(form).submit(() => {
                const { login } = controllerParams;
                const { password } = this;
                const securityCode1 = controllerParams.security_code_1;
                let securityCode2 = controllerParams.security_code_2;

                // If form already send with incorrect password or login
                if (this.afterError) {
                    if (this.useOldSecurityCodeSecond) {
                        this.security_code_2 = controllerParams.old_security_code_2;
                    }

                    securityCode2 = this.security_code_2;
                    if (login.length < 2) {
                        this.$refs.loginInput.focus();
                        return false;
                    }
                    if (password.length < 2) {
                        this.$refs.passwordInput.focus();
                        return false;
                    }
                    let return_url = this.$_GET('return');
                    if (return_url !== null) {
                        url += `?return=${return_url}`;
                    }
                    postData = {
                        login,
                        security_code_1: securityCode1,
                        security_code_2: this.password_hash(password, securityCode2),
                    };
                } else {
                    url = '/portal/login';
                    postData = {
                        login,
                        security_code_1: securityCode1,
                        security_code_2: securityCode2,
                    };
                }

                $.ajax({
                    method: 'POST',
                    url,
                    data: postData,
                    success: (data) => {
                        if (data.result === 'error') {
                            this.afterError = true;
                            this.$refs.passwordInput.setAttribute('required', '1');
                            this.errorName = data.data;
                        } else if (data.result === 'new_codes') {
                            this.useOldSecurityCodeSecond = false;
                            this.security_code_1 = data.security_code_1;
                            this.security_code_2 = data.security_code_2;
                            $(form).submit();
                        } else if (data.result === 'success') {
                            if (data.return) {
                                window.top.location.href = data.return;
                            } else {
                                window.top.location.href = '/portal';
                            }
                        } else if (data.result === 'regenerate_csrf') {
                            this.login = '';
                            this.password = '';

                            csrf.setCsrfToken('_csrf_token', data.csrf_token);

                            this.security_code_1 = data.security_code_1;
                            this.security_code_2 = data.security_code_2;

                            this.errorName = data.data;
                        }
                    },
                });
                return false;
            });
            // In first time this form will be sent automatically
            $(form).submit();
        },
        onTwoFactorGenerate(data, isResend = false) {
            $.ajax({
                url: '/portal/login--get-info-two-factor-code',
                type: 'POST',
                data,
                dataType: 'json',
                success: (data) => {
                    this.errorName = '';
                    if (data.result === 'error') {
                        this.errorName = data.data;
                    } else {
                        this.twoFactorForm.processing = true;
                        this.twoFactorForm.generatedCode = data.generated_code;
                        this.twoFactorForm.twoFactorAuthType = data.two_factor_auth_type;
                        this.twoFactorForm.recipient = data.recipient;
                        this.twoFactorForm.permissionResendCode = data.permission_resend_code;
                        this.twoFactorForm.timeoutForResetCode = data.timeout_for_reset_code;
                        if (isResend) {
                            show_success(this.$t('portal', 'Successfully sent'), 4);
                        }
                        this.initTimer();
                    }
                },
                complete: () => {
                    this.loading = false;
                },
            });

            return false;
        },
        initTimer() {
            clearTimeout(this.twoFactorForm.updateTimeout);
            this.twoFactorForm.updateTimeout = setTimeout(() => {
                this.updatePermissionResendCodeState();
            }, this.twoFactorForm.timeoutForResetCode * 1000);
            if (!this.twoFactorForm.permissionResendCode) {
                this.twoFactorForm.secondsLeft = this.twoFactorForm.timeoutForResetCode;
            }
            clearInterval(this.twoFactorForm.interval);
            this.twoFactorForm.interval = setInterval(() => {
                if (!this.twoFactorForm.permissionResendCode) {
                    this.twoFactorForm.secondsLeft -= 1;
                }
            }, 1000);
        },
        updatePermissionResendCodeState() {
            promisifiedAjax({
                method: 'GET',
                url: '/portal/login--check-permission-resend-code',
            }).then((response) => {
                response = formattedResponse(response);
                this.twoFactorForm.permissionResendCode = response.permissionResendCode;
            }).catch(() => {});
        },
        resendCode() {
            if (!this.permissionResendCode) {
                return false;
            }
            this.loading = true;
            this.twoFactorForm.permissionResendCode = false;
            this.onTwoFactorGenerate({
                login: this.login,
                security_code_1: this.security_code_1,
                security_code_2: this.password_hash(this.password, this.security_code_2),
                resend_code: true,
            }, true);
        },
        handleLogin() {
            this.twoFactorForm.permissionResendCode = false;
            this.loading = true;
            let security_code_2 = this.password_hash(this.password, this.security_code_2);
            let code = this.$refs.codeInput.value;
            let data = {
                login: this.login,
                security_code_1: this.security_code_1,
                security_code_2,
            };

            if (this.twoFactorForm.active && !this.twoFactorForm.generatedCode) {
                // Only for 2FA
                this.onTwoFactorGenerate(data);
                return false;
            }

            let url = '/portal/login';
            let return_url = this.$_GET('return');
            if (return_url !== null) {
                url += `?return=${return_url}`;
            }
            data.code = code;

            $.ajax({
                method: 'POST',
                url,
                data,
                dataType: 'json',
                success: (data) => {
                    if (data.result === 'error') {
                        if (data.permission_resend_code === true) {
                            this.twoFactorForm.permissionResendCode = data.permission_resend_code;
                        }
                        this.errorName = data.data;
                    } else if (data.result === 'new_codes') {
                        this.security_code_1 = data.security_code_1;
                        this.security_code_2 = data.security_code_2;
                        this.handleLogin();
                    } else if (data.result === 'success') {
                        this.use_old_security_code_2 = false;
                        if (data.return) {
                            let { hash } = document.location;
                            window.top.location.href = data.return + (hash || '');
                        } else {
                            window.top.location.href = '/portal/dashboard';
                        }
                    } else if (data.result === 'regenerate_csrf') {
                        this.login = '';
                        this.password = '';

                        csrf.setCsrfToken('_csrf_token', data.csrf_token);

                        this.security_code_1 = data.security_code_1;
                        this.security_code_2 = data.security_code_2;

                        this.errorName = data.data;
                    }
                },
                complete: () => {
                    this.loading = false;
                },
            });

            return false;
        },
    },
    computed: {
        showResendCodeTimer() {
            return this.twoFactorForm.secondsLeft > 0;
        },
        resendCodeClasses() {
            if (!this.permissionResendCode) {
                return '';
            }
            return 'btn btn-sm btn-primary btn-block active';
        },
        permissionResendCode() {
            return this.processingTwoFactor && this.twoFactorForm.permissionResendCode;
        },
        processingTwoFactor() {
            return this.twoFactorForm.active && this.twoFactorForm.processing;
        },
        logo() {
            return spl_config.header;
        },
        errorMessage() {
            let message = '';
            switch (this.errorName) {
                case 'incorrect_login': message = this.$t('portal', 'Login or password incorrect'); break;
                case 'incorrect_code': message = this.$t('portal', 'Incorrect code'); break;
                case 'incorrect_csrf': message = this.$t('common', 'The session has expired. Please re-login.'); break;
                case 'incorrect_data': message = this.$t('common', 'The data is incorrect. Please contact our technical support.'); break;
                default: message = ''; break;
            }
            return message;
        },
        twoFactorInfo() {
            let message = '';
            if (!this.processingTwoFactor) {
                return message;
            }

            if (empty(this.twoFactorForm.recipient)) {
                return this.$t('portal', 'Enter the 2-factor verification code');
            }

            let type = this.twoFactorForm.twoFactorAuthType;
            if (type === 'mail_sms' && !empty(this.twoFactorForm.recipient.email) && !empty(this.twoFactorForm.recipient.phone)) {
                return this.$t('portal', 'The 2-Factor confirmation code has been sent to number {phone} and email {email}', {
                    phone: this.twoFactorForm.recipient.phone,
                    email: this.twoFactorForm.recipient.email,
                });
            }

            if ((type === 'sms' || type === 'mail_sms') && !empty(this.twoFactorForm.recipient.phone)) {
                return this.$t('portal', 'The 2-Factor confirmation code has been sent to number {phone}', {
                    phone: this.twoFactorForm.recipient.phone,
                });
            }

            if ((type === 'mail' || type === 'mail_sms') && !empty(this.twoFactorForm.recipient.email)) {
                return this.$t('portal', 'The 2-Factor confirmation code has been sent to email {email}', {
                    email: this.twoFactorForm.recipient.email,
                });
            }

            return message;
        },
    },
    destroyed() {
        clearInterval(this.twoFactorForm.interval);
    },
    components: {
        XLanguageSelector,
        PortalLoginLayout,
        XAddonCode,
        XForm,
        XComplexInput,
        XInputHidden,
        XBootstrapSpinner,
    },
};
</script>
