var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-portal-form-wrap login-iframe"},[_c('div',{staticClass:"login-before"},[_c('x-addon-code',{attrs:{"page":"controllers\\\\portal\\\\LoginController","location":"before_content","app":this}})],1),_c('h4',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAlreadyLogged),expression:"showAlreadyLogged"}],staticClass:"text-center",attrs:{"id":"open_portal_link"}},[_vm._v(" "+_vm._s(_vm.$t('portal', 'You are already logged - '))+" "),_c('a',{attrs:{"href":_vm.loggedUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('portal', 'open portal')))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFormTitle),expression:"showFormTitle"}],staticClass:"login-portal-heading"},[_vm._v(" "+_vm._s(_vm.$t('portal', 'Customer login'))+" ")]),_c('form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLogged && _vm.showForm),expression:"!isLogged && showForm"}],ref:"customerLoginForm",staticClass:"form-signin",attrs:{"id":"customerLoginForm","method":"POST"}},[_c('x-input-hidden',{attrs:{"id":"security_code_1","name":"LoginForm[security_code_1]"},model:{value:(_vm.model.security_code_1),callback:function ($$v) {_vm.$set(_vm.model, "security_code_1", $$v)},expression:"model.security_code_1"}}),_c('x-input-hidden',{attrs:{"id":"security_code_2","name":"LoginForm[security_code_2]"},model:{value:(_vm.model.security_code_2),callback:function ($$v) {_vm.$set(_vm.model, "security_code_2", $$v)},expression:"model.security_code_2"}}),_c('div',{staticClass:"form-input-group login"},[_c('x-complex-input',{attrs:{"type":"text","params":{
                    options: {
                        'placeholder': _vm.authBy === 'email' ? _vm.$t('portal', 'Email address') : _vm.$t('portal', 'Login'),
                        'name': 'LoginForm[login]',
                        'class': 'form-control',
                        'autocomplete': 'username',
                        'autofocus': false,
                        'required': true,
                    },
                }},model:{value:(_vm.model.login),callback:function ($$v) {_vm.$set(_vm.model, "login", $$v)},expression:"model.login"}})],1),_c('div',{staticClass:"form-input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.password),expression:"model.password"}],staticClass:"form-control password",attrs:{"id":"password","type":"password","name":"LoginForm[password]","placeholder":_vm.$t('portal', 'Password'),"autocomplete":"old-password","required":""},domProps:{"value":(_vm.model.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "password", $event.target.value)}}})]),_c('div',{staticClass:"form-input-group"},[_c('button',{staticClass:"btn btn-sm btn-primary btn-block",attrs:{"id":"submit_form"},on:{"click":function($event){$event.preventDefault();return _vm.handleForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('portal', 'Sign in'))+" ")])])],1),_c('div',{staticClass:"login-after"},[_c('x-addon-code',{attrs:{"page":"controllers\\\\portal\\\\LoginController","location":"after_content","app":this}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }